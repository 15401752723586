<template>
  <div class="header">

    <div class="header__items">
      <router-link
        tag="a"
        :to="{ name: 'page-main' }"
      >
        <img
          :src="header?.content?.logoLink"
          alt="Logo"
          class="header__logo"
        >
      </router-link>
    </div>

    <div class="header__items desktop links" :style="isAuthenticated ? 'height: calc(100vh - 210px)' : 'height: calc(100vh - 160px)'">

      <router-link
        v-if="isAdmin"
        tag="a"
        :to="{ name: 'page-admin-rates' }"
        class="header__link"
      >
        {{ $dictionary.rates }}
      </router-link>
      <router-link
        v-if="isAdmin"
        tag="a"
        :to="{ name: 'page-admin-cards' }"
        class="header__link"
      >
        {{ $dictionary.cards }}
      </router-link>
      <router-link
        v-if="isAdmin"
        tag="a"
        :to="{ name: 'page-admin-markets' }"
        class="header__link"
      >
        {{ $dictionary.markets }}
      </router-link>
      <!-- <router-link
        v-if="isAdmin"
        tag="a"
        :to="{ name: 'page-admin-markets' }"
        class="header__link"
      >
        {{ $dictionary.markets }}
      </router-link> -->
      <router-link
        v-if="isAdmin"
        tag="a"
        :to="{ name: 'page-admin-orders' }"
        class="header__link"
      >
        {{ $dictionary.orders }}
      </router-link>
      <router-link
        v-if="isAdmin"
        tag="a"
        :to="{ name: 'page-admin-wallets' }"
        class="header__link"
      >
        {{ $dictionary.wallets }}
      </router-link>
      <router-link
        v-if="isAdmin"
        tag="a"
        :to="{ name: 'page-admin-partners' }"
        class="header__link"
      >
        {{ $dictionary.partners }}
      </router-link>
      <router-link
        v-if="isAdmin"
        tag="a"
        :to="{ name: 'page-admin-redirects' }"
        class="header__link"
      >
        {{ $dictionary.redirects }}
      </router-link>
      <router-link
        v-if="isAdmin"
        tag="a"
        :to="{ name: 'page-admin-pages' }"
        class="header__link"
      >
        {{ $dictionary.pages }}
      </router-link>
      <router-link
        v-if="isAdmin"
        tag="a"
        :to="{ name: 'page-admin-statistics' }"
        class="header__link"
      >
        {{ $dictionary.statistics }}
      </router-link>
      <router-link
        v-if="isAdmin"
        tag="a"
        :to="{ name: 'page-admin-users' }"
        class="header__link"
      >
        {{ $dictionary.users }}
      </router-link>
      <router-link
        v-if="isAdmin"
        tag="a"
        :to="{ name: 'page-admin-bans' }"
        class="header__link"
      >
        {{ $dictionary.blockLists }}
      </router-link>

      <div
        v-if="isAdmin"
        class="adminLine"
      ></div>

      <router-link
        tag="a"
        :to="{ name: 'page-main' }"
        class="header__link"
      >
        {{ $dictionary.exchange }}
      </router-link>
      <router-link
        tag="a"
        :to="{ name: 'page-feedback' }"
        class="header__link"
      >
        {{ $dictionary.feedback }}
      </router-link>
      <router-link
        tag="a"
        :to="{ name: 'page-services' }"
        class="header__link"
      >
        {{ $dictionary.services }}
      </router-link>
      <router-link
        tag="a"
        :to="{ name: 'page-news' }"
        class="header__link"
      >
        {{ $dictionary.news }}
      </router-link>
      <router-link
        tag="a"
        :to="{ name: 'page-rules' }"
        class="header__link"
      >
        {{ $dictionary.rules }}
      </router-link>
      <router-link
        tag="a"
        :to="{ name: 'page-aml' }"
        class="header__link"
      >
        {{ $dictionary.aml }}
      </router-link>
      <router-link
        tag="a"
        :to="{ name: 'page-contact' }"
        class="header__link"
      >
        {{ $dictionary.contact }}
      </router-link>
      <router-link
        tag="a"
        :to="{ name: 'page-partner' }"
        class="header__link"
      >
        {{ $dictionary.partnerProgram }}
      </router-link>
      <router-link
        tag="a"
        :to="{ name: 'page-rates' }"
        class="header__link"
      >
        {{ $dictionary.rates }}
      </router-link>
      <router-link
        tag="a"
        :to="{ name: 'page-faq' }"
        class="header__link"
      >
        {{ $dictionary.faq }}
      </router-link>
      <router-link
        tag="a"
        :to="{ name: 'page-chart' }"
        class="header__link"
      >
        {{ $dictionary.chart }}
      </router-link>

    </div>

    <div class="header__items desktop account">
      <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; width: 60%; margin: 10px auto;">
        <el-dropdown
          class="lang"
          placement="top"
        >
          <div class="header__flag">
            <img
              :src="`/langLogo/${lang}.png`"
              alt="lang logo"
            >
          </div>
          <el-dropdown-menu slot="dropdown">

            <el-dropdown-item
              v-for="(lang, i) in langOptions"
              :key="i"
            >
              <div
                class="header__flag"
                @click="setLang(lang)"
              >
                <img
                  :src="`/langLogo/${lang}.png`"
                  alt="lang logo"
                >
              </div>
            </el-dropdown-item>
            
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown
          class="lang"
          placement="top"
        >
          <div class="header__flag">
            <i class="el-icon-s-operation" style="font-size: 26px;"></i>
          </div>
          <el-dropdown-menu slot="dropdown">

            <el-dropdown-item>
              <div @click="$emit('setNewUI')">
                <i class="el-icon-s-fold" style="font-size: 26px; margin: 10px 10px 0;"></i>
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div @click="$emit('setOldUI')">
                <i class="el-icon-full-screen" style="font-size: 26px; margin: 10px 10px 0;"></i>
              </div>
            </el-dropdown-item>
            
          </el-dropdown-menu>
        </el-dropdown>
        <el-tooltip class="item" effect="light" :content="operatorStatus ? $dictionary.onlineOperatorInfo : $dictionary.offlineOperatorInfo" placement="top">
          <div
            class="operator"
            @click="isAdmin ? $emit('setAdminStatus', !adminStatus) : null"
          >
            <i
              v-if="isAdmin"
              class="el-icon-service"
              style="font-size: 26px;"
              :style="adminStatus ? 'text-shadow: 0px 0px 3px green;' : 'text-shadow: 0px 0px 3px red;'"
            />
            <i
              v-if="!isAdmin"
              class="el-icon-service"
              style="font-size: 26px;"
            />
          </div>
        </el-tooltip>
      </div>
      <router-link
        v-if="!isAuthenticated"
        tag="a"
        :to="{ name: 'page-login' }"
        class="header__link"
      >
        {{ $dictionary.login }}
      </router-link>
      <router-link
        v-if="isAuthenticated"
        tag="a"
        :to="isMonitoring ? { name: 'page-account-monitoring' } : { name: 'page-account' }"
        class="header__link"
      >
        {{ $dictionary.account }}
      </router-link>
      <div
        v-if="isAuthenticated"
        @click="$emit('logOut')"
        class="header__link"
      >
        {{ $dictionary.logout }}
      </div>

    </div>

    <nav role="navigation" class="header__items mobile">
      <div id="menuToggle">
        <input
          ref="toggle"
          type="checkbox"
        />
        <span></span>
        <span></span>
        <span></span>
        <ul id="menu">
          <div class="scroll">
            <div class="header__link">
              <router-link
                tag="a"
                :to="{ name: 'page-main' }"
              >
                {{ $dictionary.exchange }}
              </router-link>
              <div
                class="operator"
                @click="isAdmin ? $emit('setAdminStatus', !adminStatus) : null"
              >
                <!-- <p class="status">
                  {{ operatorStatus ? $dictionary.online : $dictionary.offline }}
                </p> -->
                <i
                  v-if="isAdmin"
                  class="el-icon-service"
                  style="font-size: 26px; margin: 0px 10px 0;"
                  :style="adminStatus ? 'text-shadow: 0px 0px 3px green;' : 'text-shadow: 0px 0px 3px red;'"
                />
                <!-- <i
                  v-if="!isAdmin"
                  class="el-icon-service"
                  style="font-size: 26px; margin: 0px 10px 0;"
                /> -->
              </div>
              <el-dropdown class="lang">
                <div class="header__flag">
                  <img
                    :src="`/langLogo/${lang}.png`"
                    alt="lang logo"
                  >
                </div>
                <el-dropdown-menu slot="dropdown">

                  <el-dropdown-item
                    v-for="(lang, i) in langOptions"
                    :key="i"
                  >
                    <div
                      class="header__flag"
                      @click="setLang(lang)"
                    >
                      <img
                        :src="`/langLogo/${lang}.png`"
                        alt="lang logo"
                      >
                    </div>
                  </el-dropdown-item>
                  
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <!-- <router-link
              tag="a"
              :to="{ name: 'page-main' }"
              class="header__link"
            >
              {{ $dictionary.exchange }}
            </router-link> -->
            <router-link
              tag="a"
              :to="{ name: 'page-rates' }"
              class="header__link"
            >
              {{ $dictionary.rates }}
            </router-link>
            <router-link
              tag="a"
              :to="{ name: 'page-rules' }"
              class="header__link"
            >
              {{ $dictionary.rules }}
            </router-link>
            <router-link
              tag="a"
              :to="{ name: 'page-aml' }"
              class="header__link"
            >
              {{ $dictionary.aml }}
            </router-link>
            <router-link
              tag="a"
              :to="{ name: 'page-contact' }"
              class="header__link"
            >
              {{ $dictionary.contact }}
            </router-link>
            <router-link
              tag="a"
              :to="{ name: 'page-feedback' }"
              class="header__link"
            >
              {{ $dictionary.feedback }}
            </router-link>
            <router-link
              tag="a"
              :to="{ name: 'page-news' }"
              class="header__link"
            >
              {{ $dictionary.news }}
            </router-link>
            <router-link
              tag="a"
              :to="{ name: 'page-chart' }"
              class="header__link"
            >
              {{ $dictionary.chart }}
            </router-link>
            <router-link
              tag="a"
              :to="{ name: 'page-faq' }"
              class="header__link"
            >
              {{ $dictionary.faq }}
            </router-link>
            <router-link
              tag="a"
              :to="{ name: 'page-partner' }"
              class="header__link"
            >
              {{ $dictionary.partnerProgram }}
            </router-link>
            <router-link
              v-if="!isAuthenticated"
              tag="a"
              :to="{ name: 'page-login' }"
              class="header__link"
            >
              {{ $dictionary.login }}
            </router-link>
            <router-link
              v-if="isAuthenticated"
              tag="a"
              :to="isMonitoring? { name: 'page-account-monitoring' } : { name: 'page-account' }"
              class="header__link"
            >
              {{ $dictionary.account }}
            </router-link>

            <router-link
              v-if="isAuthenticated && isAdmin"
              tag="a"
              :to="{ name: 'page-admin-rates' }"
              class="header__link"
            >
              {{ $dictionary.rates }}
            </router-link>
            <router-link
              v-if="isAuthenticated && isAdmin"
              tag="a"
              :to="{ name: 'page-admin-cards' }"
              class="header__link"
            >
              {{ $dictionary.cards }}
            </router-link>
            <router-link
              v-if="isAuthenticated && isAdmin"
              tag="a"
              :to="{ name: 'page-admin-orders' }"
              class="header__link"
            >
              {{ $dictionary.orders }}
            </router-link>
            <router-link
              v-if="isAuthenticated && isAdmin"
              tag="a"
              :to="{ name: 'page-admin-markets' }"
              class="header__link"
            >
              {{ $dictionary.markets }}
            </router-link>
            <router-link
              v-if="isAuthenticated && isAdmin"
              tag="a"
              :to="{ name: 'page-admin-wallets' }"
              class="header__link"
            >
              {{ $dictionary.wallets }}
            </router-link>
            <router-link
              v-if="isAuthenticated && isAdmin"
              tag="a"
              :to="{ name: 'page-admin-partners' }"
              class="header__link"
            >
              {{ $dictionary.partners }}
            </router-link>
            <router-link
              v-if="isAuthenticated && isAdmin"
              tag="a"
              :to="{ name: 'page-admin-redirects' }"
              class="header__link"
            >
              {{ $dictionary.redirects }}
            </router-link>
            <router-link
              v-if="isAuthenticated && isAdmin"
              tag="a"
              :to="{ name: 'page-admin-pages' }"
              class="header__link"
            >
              {{ $dictionary.pages }}
            </router-link>
            <router-link
              v-if="isAuthenticated && isAdmin"
              tag="a"
              :to="{ name: 'page-admin-statistics' }"
              class="header__link"
            >
              {{ $dictionary.statistics }}
            </router-link>
            <router-link
              v-if="isAuthenticated && isAdmin"
              tag="a"
              :to="{ name: 'page-admin-users' }"
              class="header__link"
            >
              {{ $dictionary.users }}
            </router-link>
            <router-link
              v-if="isAuthenticated && isAdmin"
              tag="a"
              :to="{ name: 'page-admin-bans' }"
              class="header__link"
            >
              {{ $dictionary.blockLists }}
            </router-link>

            <div
              v-if="isAuthenticated"
              @click="$emit('logOut')"
              class="header__link"
            >
              {{ $dictionary.logout }}
            </div>

          </div>
        </ul>
      </div>
    </nav>
  </div>
</template>
<script>
export default {
  name: 'Header',
  props: {
    isAuthenticated: {
      type: Boolean,
      default: false
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    isMonitoring: {
      type: Boolean,
      default: false
    },
    operatorStatus: {
      type: Boolean,
      default: false
    },
    adminStatus: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    header () {
      return this.$store.state.global.components.header
    },
    lang () {
      const match = document.cookie.match(new RegExp('(^| )language=([^;]+)'))
      return match ? match[2] : 'ru'
    },
    langOptions () {
      return ['ru', 'en'].filter(lang => lang !== this.lang)
    }
  },
  watch: {
    $route () {
      this.$refs.toggle.checked = false
    }
  },
  methods: {
    setLang (lang) {
      document.cookie = `language=${lang};path=/`;
      window.location.reload();
    }
  }
}
</script>
