import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './global/messages'
import './global/fieldsTitle'
import './global/status'
import './global/dictionary'

import '@/plugins'
import '@/services/api'

import '@/styles/main.styl'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
